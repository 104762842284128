<template>
  <div class="page-container">
    <!--实验列表-->
    <div class="experiment-container" v-if="experimentInfo.show">
      <!--锚点-->
      <a name="experiment"></a>
      <div class="bg"></div>
      <div class="list-box">
        <div class="des-box flex flex-dr flex-center">
          <div class="title">{{ experimentInfo.name }}</div>
          <div class="sub-title flex flex-around">
            <div class="line"></div>
            <div>{{ experimentInfo.englishName }}</div>
            <div class="line"></div>
          </div>
          <div class="des">
            <p v-for="item in experimentInfo.descriptionArr">{{ item }}</p>
          </div>
        </div>
        <!--有分类、有系列-->
        <template v-if="experimentInfo.showCategory&&experimentInfo.showSeries">
          <div class="list" v-for="(categoryItem,categoryIndex) in experimentInfo.list[0]">
            <div class="category-name">{{ categoryItem.name }}</div>
            <div class="li" v-for="(seriesItem,seriesIndex) in categoryItem.series">
              <div class="title flex flex-start">
                <div class="margin-bg"></div>
                <div class="text-bg">
                  <div>{{ seriesItem.name }}</div>
                </div>
                <div class="margin-bg"></div>
              </div>
              <div class="sub-list flex flex-start flex-wrap">
                <div class="sub-li" v-for="(experimentItem,experimentIndex) in seriesItem.experiments">
                  <img :src="experimentItem.product_iconLeft" alt="" class="left-icon"
                       v-if="experimentItem.product_iconLeft">
                  <div class="bg">
                    <img :src="experimentItem.product_bg+'?imageView2/1/w/290/h/183/q/75'">
                  </div>
                  <div class="info">
                    <img class="avatar" :src="experimentItem.product_icon" alt="">
                    <div class="flex flex-dr flex-center">
                      <div class="title">{{ experimentItem.name }}</div>
                      <div class="des-main word-hr ellipsis">{{ experimentItem.subName }}</div>
                      <!--最多3行 每行18字 第三行超出省略号显示-->
                      <div class="des word-hr ellipsis" v-html="experimentItem.description"></div>
                      <div class="buttons">
                        <a class="button" href="javascript:void(0)"
                           @click="ExperimentMethods().clickViewExperiment(experimentItem,seriesItem.name)"
                           :data-event-info='JSON.stringify({type:"button",name:"项目-点击查看详情按钮",
                      page:"/product",info:{name:experimentItem.name}
                      })'
                        >查看详情</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--              <div class="xi-list sub-list flex flex-start flex-wrap" v-if="seriesItem.name.indexOf('近平')>-1">-->
              <!--                <div class="sub-li xi-li" v-for="(experimentItem,experimentIndex) in seriesItem.experiments">-->
              <!--                  <div class="bg">-->
              <!--                    <img-->
              <!--                        :src="experimentIndex>0?experimentItem.product_bg+'?imageView2/1/w/290/h/300/q/75':experimentItem.product_bg">-->
              <!--                    <div class="buttons">-->
              <!--                      <a class="button" href="javascript:void(0)"-->
              <!--                         @click="ExperimentMethods().clickXiOne(experimentItem,seriesItem.name)">查看详情</a>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
          </div>
        </template>
        <!--有分类、无系列-->
        <template v-if="experimentInfo.showCategory&&experimentInfo.showSeries===false">
          <div class="list" v-for="(categoryItem,categoryIndex) in experimentInfo.list[1]">
            <div class="category-name">{{ categoryItem.name }}</div>
            <div class="li">
              <div class="sub-list flex flex-start flex-wrap">
                <div class="sub-li" v-for="(experimentItem,experimentIndex) in categoryItem.experiments">
                  <img :src="experimentItem.product_iconLeft" alt="" class="left-icon"
                       v-if="experimentItem.product_iconLeft">
                  <div class="bg">
                    <img :src="experimentItem.product_bg+'?imageView2/1/w/290/h/183/q/75'" alt="">
                  </div>
                  <div class="info">
                    <img class="avatar" :src="experimentItem.product_icon" alt="">
                    <div class="flex flex-dr flex-center">
                      <div class="title">{{ experimentItem.name }}</div>
                      <div class="des-main word-hr ellipsis">{{ experimentItem.subName }}</div>
                      <!--最多3行 每行18字 第三行超出省略号显示-->
                      <div class="des word-hr ellipsis" v-html="experimentItem.description"></div>
                      <div class="buttons">
                        <a class="button" href="javascript:void(0)"
                           @click="ExperimentMethods().clickViewExperiment(experimentItem,'')">查看详情</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!--无分类、有系列-->
        <template v-if="experimentInfo.showCategory===false&&experimentInfo.showSeries">
          <div class="list">
            <div class="li" v-for="(seriesItem,seriesIndex) in experimentInfo.list[2]">
              <div class="title flex flex-start">
                <div class="margin-bg"></div>
                <div class="text-bg">
                  <div>{{ seriesItem.name }}</div>
                </div>
                <div class="margin-bg"></div>
              </div>
              <div class="sub-list flex flex-start flex-wrap">
                <div class="sub-li" v-for="(experimentItem,experimentIndex) in seriesItem.experiments">
                  <img :src="experimentItem.product_iconLeft" alt="" class="left-icon"
                       v-if="experimentItem.product_iconLeft">
                  <div class="bg">
                    <img :src="experimentItem.product_bg+'?imageView2/1/w/290/h/183/q/75'" alt="">
                  </div>
                  <div class="info">
                    <img class="avatar" :src="experimentItem.product_icon" alt="">
                    <div class="flex flex-dr flex-center">
                      <div class="title">{{ experimentItem.name }}</div>
                      <div class="des-main word-hr ellipsis">{{ experimentItem.subName }}</div>
                      <!--最多3行 每行18字 第三行超出省略号显示-->
                      <div class="des word-hr ellipsis" v-html="experimentItem.description"></div>
                      <div class="buttons">
                        <a class="button" href="javascript:void(0)"
                           @click="ExperimentMethods().clickViewExperiment(experimentItem,seriesItem.name)">查看详情</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!--无分类、无系列-->
        <template v-if="experimentInfo.showCategory===false&&experimentInfo.showSeries===false">
          <div class="list" style="margin-top: 30px">
            <div class="li">
              <div class="sub-list flex flex-start flex-wrap">
                <div class="sub-li" v-for="(experimentItem,experimentIndex) in experimentInfo.list[3]">
                  <img :src="experimentItem.product_iconLeft" alt="" class="left-icon"
                       v-if="experimentItem.product_iconLeft">
                  <div class="bg">
                    <img :src="experimentItem.product_bg+'?imageView2/1/w/290/h/183/q/75'" alt="">
                  </div>
                  <div class="info">
                    <img class="avatar" :src="experimentItem.product_icon" alt="">
                    <div class="flex flex-dr flex-center">
                      <div class="title">{{ experimentItem.name }}</div>
                      <div class="des-main word-hr ellipsis">{{ experimentItem.subName }}</div>
                      <!--最多3行 每行18字 第三行超出省略号显示-->
                      <div class="des word-hr ellipsis" v-html="experimentItem.description"></div>
                      <div class="buttons">
                        <a class="button" href="javascript:void(0)"
                           @click="ExperimentMethods().clickViewExperiment(experimentItem,'')">查看详情</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <!--产品介绍弹窗-->
      <div v-if="experimentDialog" class="dialog-container flex flex-dr flex-center experiment-dialog">
        <div class="mask" @click="ExperimentMethods().closeExperimentDialog()"></div>
        <div class="dialog-box">
          <div class="bg"></div>
          <img src="@/assets/icon/dialog_close.png" alt="关闭" class="close-icon"
               @click="ExperimentMethods().closeExperimentDialog()">
          <div class="info-box">
            <div class="header-box flex flex-start">
              <img :src="experimentInfo.experiment.product_icon" class="avatar" alt="">
              <div class="name-box">
                <div class="name">{{ experimentInfo.experiment.name }}</div>
                <div class="series">{{ experimentInfo.experiment.seriesName }}</div>
              </div>
              <div>
                <div class="enter-btn webAnalytics-event-btn" v-if="!experimentDialogEnterBtn" :data-event-info='JSON.stringify({type:"button",name:"项目-点击立即体验按钮",
                page:"/product",info:{name:experimentInfo.experiment.name}
                })'
                     @click="ExperimentMethods().clickEnterBtn()"></div>
                <transition name="slide-fade">
                  <div class="view-now-btn" v-if="experimentDialogEnterBtn">联系客服，免费体验：{{
                      infoManage.contactObj.phone
                    }}
                  </div>
                </transition>
              </div>
            </div>
            <div class="description-box">
              <div class="title">实验简介：</div>
              <div class="content">
                <p v-for="p in experimentInfo.experiment.experimentDescription.split('\n')">{{ p }}</p>
              </div>
            </div>
            <div class="view-box flex flex-between">
              <div class="video-box" v-if="experimentInfo.experiment.product_video">
                <video id="v1" loop="" preload="" muted="" x-webkit-airplay="true" airplay="allow"
                       webkit-playsinline="true" playsinline="true" :src="experimentInfo.experiment.product_video"
                       class="video1 video"
                       draggable="true"></video>
                <img src="@/assets/product/dialog/play.png" alt="" class="play-btn"
                     @click="e=>ExperimentMethods().playVideo(e)">
              </div>
              <el-image v-if="!experimentInfo.experiment.product_video" class="video-box-no" fit="cover"
                        :src="experimentInfo.experiment.product_info_img+experimentDialogWaterMark"
                        :preview-src-list="experimentDialogPicList"></el-image>
              <div class="img-box flex flex-around flex-wrap">
                <el-image
                    class="img"
                    fit="cover"
                    :src="experimentInfo.experiment.product_info_img_1+experimentDialogWaterMark"
                    :preview-src-list="experimentDialogPicList">
                </el-image>
                <el-image
                    class="img"
                    fit="cover"
                    :src="experimentInfo.experiment.product_info_img_2+experimentDialogWaterMark"
                    :preview-src-list="experimentDialogPicList">
                </el-image>
                <el-image
                    class="img"
                    fit="cover"
                    :src="experimentInfo.experiment.product_info_img_3+experimentDialogWaterMark"
                    :preview-src-list="experimentDialogPicList">
                </el-image>
                <el-image
                    class="img"
                    fit="cover"
                    :src="experimentInfo.experiment.product_info_img_4+experimentDialogWaterMark"
                    :preview-src-list="experimentDialogPicList">
                </el-image>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--视频大窗播放-->
      <div v-if="experimentVideoDialog" class="dialog-container flex flex-dr flex-center experiment-video-dialog">
        <div class="mask" @click="experimentVideoDialog=false"></div>
        <div class="dialog-box">
          <img src="@/assets/icon/dialog_close.png" alt="关闭" class="close-icon" @click="experimentVideoDialog=false">
          <!--          <img src="@/assets/icon/video-view-btn.png" alt="查看详情" class="view-icon">-->
          <video controls id="v2" autoplay loop="" preload="" muted="" x-webkit-airplay="true" airplay="allow"
                 webkit-playsinline="true" playsinline="true" :src="experimentInfo.experiment.product_video"
                 class="video2 video"
                 draggable="true"></video>
        </div>
      </div>
    </div>
    <!--VR列表-->
    <div class="vr-container" v-if="vrInfo.show">
      <!--锚点-->
      <a name="vr"></a>
      <div class="bg"></div>
      <div class="list-box">
        <div class="des-box flex flex-dr flex-center">
          <div class="title">{{ vrInfo.name }}</div>
          <div class="sub-title flex flex-around">
            <div class="line"></div>
            <div>{{ vrInfo.englishName }}</div>
            <div class="line"></div>
          </div>
          <div class="des">
            <p v-for="item in vrInfo.descriptionArr">{{ item }}</p>
          </div>
        </div>
        <div class="list">
          <!--有系列-->
          <div class="li" v-for="(seriesItem,seriesIndex) in vrInfo.list[0]" v-if="vrInfo.showSeries">
            <div class="title flex flex-start">
              <div class="margin-bg"></div>
              <div class="text-bg">
                <div>{{ seriesItem.name }}</div>
              </div>
              <div class="margin-bg"></div>
            </div>
            <div class="sub-list flex flex-start flex-wrap">
              <div class="sub-li" v-for="(vrItem,vrIndex) in seriesItem.vrs"
                   :style="vrIndex>2?`background-image:url(${vrItem.product_bg});display:none`:`background-image:url(${vrItem.product_bg});`">
                <div class="title">{{ vrItem.name }}</div>
                <div class="info flex flex-dr flex-center" @click="VrMethods().clickOne(vrItem)">
                  <img class="icon" src="@/assets/product/glass.png" alt="">
                  <div class="series-name">{{ seriesItem.name }}</div>
                  <div class="name">{{ vrItem.name }}</div>
                  <div class="des word-hr" v-html="vrItem.description"></div>
                </div>
              </div>
            </div>
            <div class="more more-btn" @click="VrMethods().clickMoreBtn(seriesIndex)" v-if="seriesItem.vrs.length>3">
              <span>查看更多</span>
              <i class="el-icon-arrow-down"></i>
            </div>
            <div class="more hide-btn" @click="VrMethods().clickHideBtn(seriesIndex)" style="display: none">
              <span>收起</span>
              <i class="el-icon-arrow-up"></i>
            </div>
          </div>
          <!--无系列-->
          <div class="li" v-if="vrInfo.showSeries===false">
            <div class="sub-list flex flex-start flex-wrap">
              <div class="sub-li" v-for="(vrItem,vrIndex) in vrInfo.list[1]"
                   :style="vrIndex>2?`background-image:url(${vrItem.product_bg});display:none`:`background-image:url(${vrItem.product_bg});`">
                <div class="title">{{ vrItem.name }}</div>
                <div class="info flex flex-dr flex-center" @click="VrMethods().clickOne(vrItem)">
                  <img class="icon" src="@/assets/product/glass.png" alt="">
                  <div class="series-name"></div>
                  <div class="name">{{ vrItem.name }}</div>
                  <div class="des word-hr" v-html="vrItem.description"></div>
                </div>
              </div>
            </div>
            <div class="more more-btn" @click="VrMethods().clickMoreBtn(0)" v-if="vrInfo.list[1].length>3">
              <span>查看更多</span>
              <i class="el-icon-arrow-down"></i>
            </div>
            <div class="more hide-btn" @click="VrMethods().clickHideBtn(0)" style="display: none">
              <span>收起</span>
              <i class="el-icon-arrow-up"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--考试系统-->
    <div class="examSystem-container flex flex-start flex-dr" v-if="examInfo.show">
      <!--锚点-->
      <a name="exam"></a>
      <div class="des-box flex flex-center flex-dr">
        <div class="title">{{ examInfo.name }}</div>
        <div class="sub-title flex flex-start">
          <div class="line"></div>
          <div>{{ examInfo.englishName }}</div>
          <div class="line"></div>
        </div>
        <div class="des word-hr ellipsis" v-html="examInfo.des"></div>
      </div>
      <div class="img"></div>
    </div>
    <!--技术支持-->
    <div class="tech-container  flex flex-start flex-dr" v-if="techInfo.show">
      <!--锚点-->
      <a name="tech"></a>
      <div class="des-box flex flex-center flex-dr">
        <div class="title"> {{ techInfo.name }}</div>
        <div class="sub-title flex flex-start">
          <div class="line"></div>
          <div>{{ techInfo.englishName }}</div>
          <div class="line"></div>
        </div>
      </div>
      <div class="list">
        <div class="li flex flex-dr flex-center" v-for="(item,index) in techInfo.list"
             @click="TechMethods().openDialog(item)">
          <div class="bg"></div>
          <div class="title">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <!--自定义楼层-->
    <div class="floor-container">
      <template v-for="(floorItem,floorIndex) in  floorInfo.lists">
        <div class="floor1-container flex flex-start flex-dr" v-if="floorItem.type==='1'&&floorItem.show===true"
             style="margin-top: 50px">
          <div class="des-box flex flex-center flex-dr">
            <div class="title">{{ floorItem.name }}</div>
            <div class="sub-title flex flex-start">
              <div class="line"></div>
              <div>{{ floorItem.englishName }}</div>
              <div class="line"></div>
            </div>
          </div>
          <div class="img" :style='"background-image: url("+floorItem.bigImg+")"'></div>
        </div>
        <div class="floor2-container  flex flex-start flex-dr" v-if="floorItem.type==='2'&&floorItem.show===true">
          <div class="des-box flex flex-center flex-dr">
            <div class="title"> {{ floorItem.name }}</div>
            <div class="sub-title flex flex-start">
              <div class="line"></div>
              <div>{{ floorItem.englishName }}</div>
              <div class="line"></div>
            </div>
          </div>
          <div class="list">
            <div class="li flex flex-dr flex-center" v-for="(item,index) in floorItem.list" v-if="item.show"
                 :style='"background-image: url("+item.child_floor_bg+")"' @click="TechMethods().openDialog(item)">
              <div class="bg"></div>
              <div class="title">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </template>

    </div>
    <!--技术支持和自定义楼层2-弹窗介绍-->
    <div v-if="floorDialog" class="dialog-container flex flex-dr flex-center floor-dialog">
      <div class="mask" @click="TechMethods().closeDialog()"></div>
      <div class="dialog-box">
        <div class="bg">
          <div class="back-bg"></div>
          <div class="name">{{ floorDialogInfo.name }}</div>
        </div>
        <img src="@/assets/icon/dialog_close.png" alt="关闭" class="close-icon" @click="TechMethods().closeDialog()">
        <div class="info-box">
          <div class="des word-hr ellipsis" v-html="floorDialogInfo.des" style="text-align: left"></div>
          <!--        <div class="des">-->
          <!--          <p v-for="item in floorDialogInfo.des.split('\n')">{{ item }}</p>-->
          <!--        </div>-->
          <div class="buttons flex flex-dr flex-center">
            <transition name="slide-fade">
              <div class="enter-btn" v-show="!floorDialogEnterBtn"
                   @click="TechMethods().clickEnterBtn()"></div>
            </transition>
            <transition name="slide-fade">
              <div class="view-now-btn" v-show="floorDialogEnterBtn">{{ floorDialogInfo.dialog_button_text }}：{{
                  infoManage.contactObj.phone
                }}
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ConfigModel} from "@/model/erp/ConfigModel";
import $ from "jquery"

export default {
  name: "Product",
  data() {
    return {
      experimentDialog: false,
      experimentVideoDialog: false,
      url: 'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg',
      videoPlay: false,
      pageInfo: {},
      experimentInfo: {
        experiment: {},
        list: []
      },
      experimentDialogPicList: [],
      experimentDialogEnterBtn: false,
      // 实验弹窗图 水印设置 5pm65LqR6bi_6YGT SE9OREFP
      // experimentDialogWaterMark: "?watermark/4/text/SE9OREFP/font/5a6L5L2T/fontsize/300/fill/IzAwMDAwMA==/dissolve/60/rotate/-25/uw/150/uh/150/resize/1",
      experimentDialogWaterMark: "",
      indexExperimentManage: {},
      infoManage: {
        contactObj: {}
      },
      vrInfo: {
        list: [],
      },
      examInfo: {},
      techInfo: {},
      floorInfo: {
        lists: []
      },
      floorDialog: false,
      floorDialogInfo: {
        bg: "http://resouce.cdzyhd.com/4e355193-8851-4934-85b1-bb96fd4ddb48.png",
        name: "宣传片制作",
        des: ""
      },
      floorDialogEnterBtn: false,

    }
  },
  async mounted() {
    // css定制化
    $("#header-container .product-navs a.title").addClass("active") // 产品导航增加active
    $("#header-container .product-navs .list").css("display", "flex")
    $("#header-container a.experiment-back").hide()

    $("#header-container .product-navs").hover(function () {
      $("#header-container a.experiment-back").hide()
      $("#header-container .product-navs .list").css("display", "flex")
    }, function () {
      $("#header-container a.experiment-back").show()
      $("#header-container .product-navs .list").hide()
    })
    // 获取配置信息
    let productManage = await ConfigModel.getProductConfig();
    this.experimentInfo = productManage.experimentInfo
    this.vrInfo = productManage.vrInfo
    this.examInfo = productManage.examInfo
    this.techInfo = productManage.techInfo
    this.floorInfo = productManage.floorInfo
    let indexManage = await ConfigModel.getIndexConfig();
    this.infoManage = indexManage.infoManage
    this.indexExperimentManage = indexManage.experimentManage
    // 开始渲染
    this.$nextTick(() => {
      $("#header-container .product-navs .list").css("display", "flex")
      $("#header-container img.logo").attr("src", this.infoManage.logos.pc_logo_product) // 改变logo
      this.ExperimentMethods().drawPage()
      this.VrMethods().drawPage()
      this.ExamMethods().drawPage()
      this.TechMethods().drawPage()
    })
  },
  methods: {
    ExperimentMethods() {
      let $this = this;
      return {
        // 渲染页面
        drawPage() {
          // 头部背景
          $(".experiment-container >.bg").css("background-image", `url(${$this.experimentInfo.experiment_big_bg})`)
          // 副图背景
          if ($this.infoManage.colorType === 'blue') {
            $(".experiment-container .des-box").css("background-image", `url(${$this.experimentInfo.experiment_second_bg_blue})`)
          }
          if ($this.infoManage.colorType === 'red') {
            $(".experiment-container .des-box").css("background-image", `url(${$this.experimentInfo.experiment_second_bg_red})`)
          }
          // 介绍分段
          $this.$set($this.experimentInfo, "descriptionArr", $this.experimentInfo.description.split("\n"))
        },
        // 点击查看详情按钮
        clickViewExperiment(experiment, seriesName) {
          $this.experimentDialog = true
          document.body.style.overflow = 'hidden'
          experiment.seriesName = seriesName
          $this.experimentDialogEnterBtn = false
          $this.$set($this.experimentInfo, "experiment", experiment);
          $this.experimentDialogPicList = [
            experiment.product_info_img_1 + $this.experimentDialogWaterMark,
            experiment.product_info_img_2 + $this.experimentDialogWaterMark,
            experiment.product_info_img_3 + $this.experimentDialogWaterMark,
            experiment.product_info_img_4 + $this.experimentDialogWaterMark,
          ]
          if (!experiment.product_video && experiment.product_info_img) {
            $this.experimentDialogPicList.push(experiment.product_info_img + $this.experimentDialogWaterMark)
          }
          setTimeout(() => {
            $(".experiment-dialog .bg").css("background-image", `url(${experiment.product_info_bg + $this.experimentDialogWaterMark})`)
          }, 100)
        },
        // 关闭实验弹窗
        closeExperimentDialog() {
          $this.experimentDialog = false
          document.body.style.overflow = ''
        },
        // 点击立即体验按钮
        clickEnterBtn() {
          $this.experimentDialogEnterBtn = true
        },
        // 播放视频
        playVideo(e) {
          // this.videoPlay = true
          // document.querySelector("#v1").play()
          $this.experimentVideoDialog = true
        }
      }
    },
    VrMethods() {
      let $this = this;
      return {
        // 渲染页面
        drawPage() {
          // 头部背景
          $(".vr-container .bg").css("background-image", `url(${$this.vrInfo.vr_big_bg})`)
          // 副图背景
          if ($this.infoManage.colorType === 'blue') {
            $(".vr-container .des-box").css("background-image", `url(${$this.vrInfo.vr_second_bg_blue})`)
          }
          if ($this.infoManage.colorType === 'red') {
            $(".vr-container .des-box").css("background-image", `url(${$this.vrInfo.vr_second_bg_red})`)
          }
          // 介绍分段
          $this.$set($this.vrInfo, "descriptionArr", $this.vrInfo.description.split("\n"))
        },
        // 点击一个
        clickOne(vr) {
          window.open(vr.link)
        },
        // 点击查看更多按钮 用jquery实现
        clickMoreBtn(seriesIndex) {
          // 显示所有隐藏的vr
          let seriesSelector = `.vr-container .li:eq(${seriesIndex}) .sub-li[style*='display: none;']`
          $(seriesSelector).show()
          // 隐藏查看更多按钮
          $(`.vr-container .li:eq(${seriesIndex}) .more-btn`).hide()
          // 显示收起按钮
          $(`.vr-container .li:eq(${seriesIndex}) .hide-btn`).show()
        },
        // 点击收起按钮 只显示最开始3个
        clickHideBtn(seriesIndex) {
          let seriesSelector = `.vr-container .li:eq(${seriesIndex}) .sub-li`
          $(seriesSelector).hide()
          $(seriesSelector).filter(":lt(3)").show()
          // 显示查看更多按钮
          $(`.vr-container .li:eq(${seriesIndex}) .more-btn`).show()
          // 隐藏收起按钮
          $(`.vr-container .li:eq(${seriesIndex}) .hide-btn`).hide()
          // 跳转到vr头部，避免滑动到了底部问题
          location.href = "/product#vr"
        }
      }
    },
    ExamMethods() {
      let $this = this;
      return {
        drawPage() {
          $(".examSystem-container div.img").css("background-image", `url(${$this.examInfo.exam_big_img})`)
        }
      }
    },
    TechMethods() {
      let $this = this
      return {
        drawPage() {
          $($this.techInfo.list.forEach((li, index) => {
            index += 1
            if (li.show) {
              $(`.tech-container .list .li:nth-child(${index})`).css("background-image", `url(${li.child_floor_bg})`)
            } else {
              $(`.tech-container .list .li:nth-child(${index})`).hide()
            }
          }))
        },
        // 打开弹窗介绍
        openDialog(floorInfo) {
          $this.floorDialog = true
          document.body.style.overflow = 'hidden'
          $this.floorDialogEnterBtn = false
          $this.floorDialogInfo = floorInfo
          setTimeout(() => {
            $(".floor-dialog .bg").css("background-image", `url(${floorInfo.child_floor_dialog_bg})`)
            if (floorInfo.showName === false) {
              $(".floor-dialog .bg .back-bg").css("opacity", 0)
            }
          }, 100)
        },
        closeDialog() {
          $this.floorDialog = false
          document.body.style.overflow = ''
        },
        // 点击弹窗立即联系按钮
        clickEnterBtn() {
          $this.floorDialogEnterBtn = true
        },
      }
    },
  }
}
</script>

<style>

#header-container .right span {
  color: #000000;
}

#header-container img.white {
  display: none;
}
</style>
<style scoped lang="less">
.slide-fade-enter-active {transition: all 1.2s ease;}
.slide-fade-leave-active {transition: all 1.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);}
.slide-fade-enter, .slide-fade-leave-to{transform: scale(1.05);opacity: 0;}

.page-container {
  padding-top: 72px;
}

.experiment-container {
  > .bg {
    width: 100%;
    height: 480px;
    background-size: cover;
    background-position: 50% 50%;
    -webkit-backface-visibility: hidden;
    opacity: 1;
  }

  .list-box {
    max-width: 1280px;
    margin: 0 auto;
    margin-top: -140px;
    position: relative;
  }

  .des-box {
    width: 100%;
    height: 340px;
    background-size: cover;
    background-position: 50% 50%;
    -webkit-backface-visibility: hidden;
    opacity: 1;

    > .title {
      font-size: 26px;
      font-weight: bold;
      line-height: 35px;
    }

    .sub-title {
      font-size: 16px;
      font-weight: bold;
      line-height: 29px;
      opacity: 0.3;

      .line {
        width: 115px;
        height: 1px;
        opacity: 0.3;

        &:nth-child(1) {
          margin-right: 20px;
        }

        &:nth-child(3) {
          margin-left: 20px;
        }
      }
    }

    .des {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #333333;
      text-align: center;
    }
  }

  .list {
    .category-name {
      font-size: 26px;
      text-align: center;
      margin-top: 30px;
      margin-bottom: 5px;
    }

    .li {
      > .title {
        margin-top: 25px;
        margin-bottom: 25px;

        .margin-bg {
          display: inline-block;
          width: 12px;
          height: 33px;
          transform: skewX(-25deg);
          margin-right: 5px;
        }

        .text-bg {
          display: inline-block;
          padding: 0px 20px;
          height: 33px;
          color: #fff;
          transform: skewX(-25deg);
          margin-right: 5px;

          div {
            transform: skewX(25deg);
            font-size: 16px;
            line-height: 33px;
            font-weight: 400;
          }
        }
      }

      .sub-list {
        .sub-li {
          margin-bottom: 30px;
          margin-right: 40px;
          position: relative;

          &:nth-child(4n+4) {
            margin-right: 0px;
          }

          &:hover {
            .bg {
              img {
                transition: all 0.5s linear;
                transform: scale(1.1);
              }
            }

            .info {
              box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

              .avatar {
                margin-top: -30px;
                opacity: 0;
              }


              .des-main {
                opacity: 0;
              }

              .title {
                margin-top: -30px;
                margin-bottom: 0px;

              }

              .des {
                display: block;
                height: 65px;
              }

              .buttons {

                display: block;
              }
            }

          }


          img.left-icon {
            position: absolute;
            width: 60px;
            height: 60px;
            top: -10px;
            left: -10px;
            z-index: 10;
          }

          .bg {
            z-index: 1;
            width: 290px;
            height: 183px;
            overflow: hidden;
            position: relative;

            img {
              width: 290px;
              height: 183px;
              display: block;
              transition: all 0.5s linear;
              transform: scale(1);

            }
          }

          .info {
            position: relative; // 解决margin-top负数 和同级div的z-index问题
            padding: 25px;
            height: 180px;
            z-index: 2;
            width: 270px;
            margin: 0 auto;
            margin-top: -60px;
            text-align: center;
            background: #fff;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            border-bottom: 3px solid transparent;
            transition: all 0.2s linear;
            overflow: hidden;

            .avatar {
              display: inline-block;
              width: 60px;
              height: 60px;
              position: relative;
              margin-top: 0px;
              transition: all 0.2s linear;
              border-radius: 4px;
              box-shadow: 2px 2px 5px 1px #dedede;
            }

            .title {
              position: relative;
              font-size: 16px;
              font-weight: bold;
              margin-top: 18px;
              margin-bottom: 10px;
              transition: all 0.2s linear;
            }

            .des-main {
              transition: all 0.2s linear;
              color: #666;
              font-size: 14px;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              display: -webkit-box;
            }

            .des {
              font-weight: 300;
              font-size: 14px;
              display: none;
              color: #666;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              max-width: 210px;
            }

            .buttons {
              display: none;
              text-align: center;
              margin-top: 12px;
            }

            .button {
              width: 100px;
              line-height: 28px;
              border: 1px solid #d8d8d8;
              border-radius: 15px;
              text-align: center;
              font-size: 14px;
              color: #666666;
              position: relative;
              cursor: pointer;
              transition: all 0.2s linear;
              text-decoration: none;
              padding: 5px 20px;

              &:hover {
              }
            }

          }
        }
      }
    }
  }

  .experiment-dialog {
    border-radius: 4px;
    z-index: 2000; // 解决被遮挡问题
    .dialog-box {
      border-radius: 4px;
    }

    .bg {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      width: 100%;
      height: 330px;
      background-size: cover;
      background-position: 50% 50%;
    }

    .close-icon {
      display: inline-block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

    .info-box {
      padding: 0px 80px;
    }

    .header-box {
      position: relative;
      margin-top: -115px;

      .avatar {
        width: 130px;
        height: 130px;
        border-radius: 4px;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
        margin-right: 20px;
      }

      .name-box {
        .name {
          font-size: 33px;
          font-weight: bold;
          line-height: 44px;
          color: #FFFFFF;
          text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
          opacity: 0.9;
        }

        .series {
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;
          color: #FFFFFF;
          text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
          opacity: 0.9;
          margin-bottom: 8px;
        }
      }

      .enter-btn {
        margin-left: 300px;
        width: 97px;
        height: 29px;
        background-size: cover;
        background-position: 50% 50%;
        -webkit-backface-visibility: hidden;
        background-image: url("../assets/product/dialog/enter-btn.png");
        cursor: pointer;
      }

      .view-now-btn {
        display: inline-block;
        padding: 10px 30px;
        background-color: #ff3829;
        color: #fff;
        font-size: 14px;
        margin-left: 200px;
        border-radius: 20px;
      }

    }

    .description-box {
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      color: #333333;
      margin-top: 20px;

      .title {
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        color: #333;
      }

      .content {
        margin-top: 5px;
        font-size: 14px;
        text-indent: 2em;
        font-weight: 400;
        line-height: 19px;
        color: #333333;
      }
    }

    .view-box {
      width: 100%;
      margin-top: 25px;
      margin-bottom: 35px;

      .video-box {
        width: 337px;
        height: 223px;
        position: relative;

        video {
          position: absolute;
          left: 0;
          top: 0;
          width: 337px;
          height: 223px;
          object-fit: fill;
          z-index: 1;
        }

        .play-btn {
          position: absolute;
          // 上下左右居中
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 54px;
          height: 54px;
          cursor: pointer;
          z-index: 2;
        }
      }

      .video-box-no {
        width: 337px;
        height: 223px;
        background-size: cover;
        background-position: 50% 50%;
        -webkit-backface-visibility: hidden;
      }

      .img-box {
        width: 695px;

        .img {
          width: 334px;
          height: 106px;
          cursor: pointer;

          &:nth-child(1), &:nth-child(2) {
            margin-bottom: 11px;
          }
        }
      }
    }
  }

  .experiment-video-dialog {
    z-index: 2001;

    .dialog-box {
      width: 1200px;
      height: 720px;
    }

    .close-icon {
      display: inline-block;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      z-index: 3;
    }

    .view-icon {
      display: inline-block;
      width: 100px;
      height: 30px;
      position: absolute;
      bottom: 80px;
      right: 25px;
      cursor: pointer;
      z-index: 3;
    }

    video {
      width: 1200px;
      height: 720px;
      object-fit: fill;
    }
  }
}

.vr-container {
  margin-top: 80px;

  > .bg {
    width: 100%;
    height: 480px;
    background-size: cover;
    background-position: 50% 50%;
    opacity: 1;
  }

  .list-box {
    max-width: 1280px;
    margin: 0 auto;
    margin-top: -140px;
    position: relative;
  }

  .des-box {
    width: 100%;
    height: 340px;
    background-size: cover;
    background-position: 50% 50%;
    -webkit-backface-visibility: hidden;
    opacity: 1;

    .title {
      font-size: 26px;
      font-weight: bold;
      line-height: 35px;
    }

    .sub-title {
      font-size: 16px;
      font-weight: bold;
      line-height: 29px;
      opacity: 0.3;

      .line {
        width: 115px;
        height: 1px;
        opacity: 0.3;

        &:nth-child(1) {
          margin-right: 20px;
        }

        &:nth-child(3) {
          margin-left: 20px;
        }
      }
    }

    .des {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #333333;
      text-align: center;
    }
  }

  .list {
    .li {
      > .title {
        margin-top: 25px;
        margin-bottom: 25px;

        .margin-bg {
          display: inline-block;
          width: 12px;
          height: 33px;
          transform: skewX(-25deg);
          margin-right: 5px;
        }

        .text-bg {
          display: inline-block;
          padding: 0px 20px;
          height: 33px;
          color: #fff;
          transform: skewX(-25deg);
          margin-right: 5px;

          div {
            transform: skewX(25deg);
            font-size: 16px;
            line-height: 33px;
            font-weight: 400;
          }
        }
      }

      .sub-list {
        display: grid;
        justify-content: space-between;
        grid-template-columns: repeat(auto-fill, 400px);
        grid-gap: 10px;

        .sub-li {
          margin-bottom: 20px;
          width: 400px;
          height: 400px;
          background-size: cover;
          background-position: 50% 50%;
          -webkit-backface-visibility: hidden;
          opacity: 1;
          position: relative;
          overflow: hidden;

          .title {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 60px;
            background-color: #000;
            opacity: 0.8;
            text-align: center;
            font-size: 16px;
            line-height: 60px;
            font-weight: 400;
            color: #fff;
            transition: all 0.4s ease-out;
          }

          .info {
            cursor: pointer;
            position: absolute;
            width: 100%;
            top: 400px;
            left: 0;
            background-color: #000;
            height: 400px;
            color: #fff;
            padding: 0 20px;
            transition: all 0.3s ease;

            .icon {
              width: 32px;
              height: 32px;
              margin-bottom: 12px;
              cursor: pointer;
            }

            .series-name {
              margin-bottom: 15px;
            }

            .name {
              font-size: 19px;
              margin-bottom: 15px;
              cursor: pointer;
            }

            .des {
              font-size: 15px;
              text-align: center;
              width: 100%;
            }
          }


          &:hover {
            .title {
              bottom: -60px;
            }

            .info {
              top: 0px;
              opacity: 0.8;
            }
          }
        }
      }
    }

    .more {
      text-align: center;
      cursor: pointer;
      transition: all 0.3s linear;
      width: 100px;
      margin: 0 auto;
      margin-top: 7px;
      color: #707070;

      &:hover {
        transform: scale(1.1);
      }

      span {
        font-weight: 400;
        line-height: 21px;
        color: #707070;
        font-size: 16px;
        margin-right: 3px;
      }

      img {
        width: 22px;
        height: 22px;
        display: inline-block;
        margin-left: 4px;
      }
    }
  }
}

.examSystem-container {
  background: radial-gradient(circle, #275F97 0%, #14304C 100%);

  .title {
    color: #fff !important;
    margin-top: 56px;
  }

  .sub-title {
    color: #fff !important;
    opacity: 0.4 !important;

    .line {
      border: 1px solid #fff !important;
    }
  }

  .des {
    margin-top: 15px;
    font-size: 16px;
    font-weight: 400;
    line-height: 29px;
    color: #FFFFFF;
    opacity: 1;
    width: 700px;
  }

  div.img {
    width: 100% !important;
    height: 520px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

}

.examSystem-container, .floor1-container {
  margin-top: 80px;

  .title {
    font-size: 26px;
    font-weight: bold;
    line-height: 35px;
  }

  .sub-title {
    font-size: 16px;
    font-weight: bold;
    line-height: 29px;
    opacity: 0.3;

    .line {
      width: 115px;
      height: 1px;
      opacity: 0.3;

      &:nth-child(1) {
        margin-right: 20px;
      }

      &:nth-child(3) {
        margin-left: 20px;
      }
    }
  }

  div.img {
    margin-top: 65px;
    margin-bottom: 60px;
    width: 1280px;
    height: 711px;
    background-size: cover;
    background-position: 50% 50%;
    -webkit-backface-visibility: hidden;
    opacity: 1;
  }
}

.tech-container, .floor2-container {
  margin-top: 80px;

  .title {
    font-size: 26px;
    font-weight: bold;
    line-height: 35px;
  }

  .sub-title {
    font-size: 16px;
    font-weight: bold;
    line-height: 29px;
    opacity: 0.3;
    margin-bottom: 40px;

    .line {
      width: 115px;
      height: 1px;
      opacity: 0.3;

      &:nth-child(1) {
        margin-right: 20px;
      }

      &:nth-child(3) {
        margin-left: 20px;
      }
    }
  }

  .list {
    width: 100%;

    .li {
      width: 100%;
      height: 250px;
      background-size: cover;
      background-position: 50% 50%;
      -webkit-backface-visibility: hidden;
      cursor: pointer;
      opacity: 1;

      position: relative;

      .title {
        font-size: 32px;
        color: #fff;
        font-weight: bold;
        line-height: 42px;
        opacity: 0.6;
        transition: all 0.3s linear;
      }

      .bg {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 250px;
        background-color: #000;
        opacity: 0.01;
        transition: all 0.5s linear;
        -webkit-backface-visibility: hidden;
        -webkit-transform-style: preserve-3d;
      }

      @keyframes bg_opacity {
        0% {
          opacity: 0
        }
        100% {
          opacity: 0.6
        }
      }

      &:hover {
        .title {
          transform: scale(1.2);
          opacity: 1.0;
        }

        .bg {
          animation: bg_opacity 0.6s linear 0s 1 alternate forwards;
        }
      }
    }
  }

}

// 技术支持和楼层2弹窗
.floor-dialog {
  border-radius: 4px;
  z-index: 2000; // 解决被遮挡问题
  .dialog-box {
    border-radius: 4px;
  }

  .bg {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    width: 100%;
    height: 360px;
    background-size: cover;
    background-position: 50% 50%;

    .back-bg {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 360px;
      background-color: #000;
      opacity: 0.4;
      transition: all 0.5s linear;
      -webkit-backface-visibility: hidden;
      -webkit-transform-style: preserve-3d;
    }

    .name {
      line-height: 360px;
      text-align: center;
      font-size: 50px;
      color: #fff;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 360px;
    }

  }

  .close-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .info-box {
    padding: 30px 80px;
    color: #333;
    font-size: 14px;

    .des {
      margin-bottom: 30px;

      p {
        line-height: 22px;
      }
    }


    .buttons {
      height: 36px;
    }

    .enter-btn {
      width: 97px;
      height: 29px;
      background-size: cover;
      background-position: 50% 50%;
      -webkit-backface-visibility: hidden;
      background-image: url("../assets/product/dialog/tech-enter-btn.png");
      cursor: pointer;
      transition: all 0.2s linear;
    }

    .view-now-btn {
      display: inline-block;
      padding: 10px 30px;
      background-color: #ff3829;
      color: #fff;
      font-size: 14px;
      border-radius: 20px;
    }
  }

}
</style>
